<template>
<div class="mt-2">
    <div class="">
      <div class="mb-1 flex-wrap flex">
        <div class="" v-for="(status, index) in employeeList" :key="index" :class="`${index === 0 ? 'left-0 sticky' : ''}`">
          <div @click="toggleActiveAndInactiveB(index)" v-if="activeUer !== status.userDetailId">
            <Chip v-if="status.fullName.toLowerCase() === 'all'" :class="`${status.selected ? 'bg-gray2' : '' } py-1 cursor-pointer`"   :chipText="status.fullName"/> 
            <Chip v-else :class="`${status.selected ? 'activeView' : '' } py-1 cursor-pointer`"   :chipText="status.fullName"/>   </div>  
          <div @click="toggleActiveAndInactiveB(index)" v-else>
            <Chip  :class="`${status.selected ? 'activeView' : '' } py-1 cursor-pointer`"  :chipText="'Me'"/>  </div>   
        </div>
      </div>
      <div class="flex  flex-wrap text-text1">
        <div class="flex mb-1 flex-wrap lg:flex-nowrap w-full justify-between">
          <div class=" hidden lg:block heading-2  w-full font-bold  text-center ">
            <div class="xl:ml-72">
              <!-- {{showGoto}} -->
              <span><span v-if="showGoto" class="text-primary font-semibold heading-4 cursor-pointer " @click="selectedDateByPicker()">
            Go to current Month
            </span></span>
            <span class=" whitespace-nowrap">{{shwoTitle}}</span>
            </div>
          </div>
          <div class="text-center flex sm:justify-end flex-wrap sm:flex-nowrap gap-1 mb-1 md:mb-0">
            <div @click="$router.push({name: 'schedule'})">
            <Chip :class="`${false ? 'activeView' : '' } py-1 cursor-pointer`" style="font-size: 16px" :chipText="`1 Day`"/>
            </div>
            <div>
            <Chip :class="`${true ? 'activeView' : '' } py-1 cursor-pointer`" style="font-size: 16px" :chipText="`Month`"/>
            </div>
            <div @click="goDateCall('prev')">
            <Chip class="py-1 cursor-pointer activeView" style="font-size: 16px" :iconClass="'fa fa-chevron-left'" :chipText="``"/>
            </div>
            <div @click="goDateCall('next')">
            <Chip class="py-1 cursor-pointer activeView" style="font-size: 16px" :iconClass="'fa fa-chevron-right'" :chipText="``"/>
            </div>
          </div>
        </div>
    </div>
    </div>
    <div class="cust_card bg-white pb-10" style="overflow: auto; height: 100vh;">
      <div :style="`  max-height: fit-content; min-height: ${schedulerSectionHeight}px;`"  class="">
        <SchedulerComponent  @titleHandler="titleHandler" :titleDvalue="titleDate" :startTime="startTime" :defaultView="defaultView" :teamListData="teamListData" :scheduleData="eventList" class="left-container" v-if="!isUnSchJob && isLoad && isReady"></SchedulerComponent>
      </div>
        
        <div  style="width: 100%; height: 700px"  class=" p-4">
          <!-- <VisitMapLocation v-if="false" :scheduleData="eventList" :lastEmpData="empArray" :isUnSchedule="isUnSchJob" class="left-container"/> -->
          <VisitMapLocation  :scheduledList="eventList" :unscheduleventList="unscheduleventList"  :lastEmpData="empArray" :isUnSchedule="isUnSchJob" class="left-container"/>
        </div>
        <div class="p-4">
          <ScheduleListing :listTitle="'Schedule List'" :scheduled="true"  :scheduleData="eventList"  />
        </div>
        <div class=" p-4">
          <!-- {{unscheduleventList}} -->
          <ScheduleListing :listTitle="'Unschedule list'" :scheduled="false" :scheduleData="unscheduleventList"  />
        </div>
    </div>
  </div>
</template>

<script>
import {jsCoreDateCreator} from '@/utils/convertDateAndTime.js'
import Chip from '@/View/components/chip.vue';
import moment from 'moment'
import SchedulerComponent from './components/SchedulerComponent.vue'
import MyJobApp from "@/api/MyJobApp.js";
import VisitMapLocation from './components/visitMapLocation.vue';
import ScheduleListing from './components/scheduleListing.vue'
export default {
  name: "app",
  components: { 
    SchedulerComponent,
    VisitMapLocation,
    ScheduleListing,
    // Button,
    Chip
  },
  data() {
    return {
      shwoTitle: '',
      showGoto: false,
      titleDate: moment(new Date()).format('YYYY-MM-DD'),
      startTime: moment(new Date()).format('YYYY-MM-DD'),
      activeUer: 0,
      isLoad: true,
      isReady: false,
      taskList: [],
      empArray: [],
      isUnSchJob: false,
      scheduleStartDt: '',
      scheduleEndDt: '',
      eventList: [],
      userFilterlist: "",
      applyedUserFilters: "",
      employeeList: [],
      teamListData: null,
      schedulerSectionHeight: 0,
      defaultView: 'month',
      defaultSubView: 'time',
      unscheduleventList: [],
    };
  },
  created () {
    this.eventList = []
    this.getTeamList()
    if (this.$route.query.subView !== undefined) {
      if (this.$route.query.subView !== '') {
        this.defaultSubView = this.$route.query.subView
      }
    }
    if (this.$route.query.view !== undefined) {
      if (this.$route.query.view !== '') {
        this.defaultView = this.$route.query.view
      }
    }
    if (this.$route.query.stime !== undefined) {
      if (this.$route.query.stime !== '') {
        this.scheduleStartDt = this.$route.query.stime
        this.startTime = this.$route.query.stime
      }
    }
    if (this.$route.query.etime !== undefined) {
      if (this.$route.query.etime !== '') {
        this.scheduleEndDt = this.$route.query.etime
      }
    }
    if (this.$route.query.user !== undefined) {
      if (this.$route.query.user !== '') {
        this.applyedUserFilters = this.$route.query.user
           this.userFilterlist = this.$route.query.user.split(',')
      }
    }
    
  },
  mounted () {
    window.addEventListener('popstate', this.loadRouterWise);
    this.eventList = []
    if (this.defaultView === 'month' || this.defaultView === 'timelineFourDay') {
      this.eventList = []
      this.getJoblist()
    }
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
    document.title = 'Schedule'
    document.body.style = 'overflow: auto;'
    this.$root.$on('changesinGmap', (data) => {
      this.manageRouterQuery("8")
      this.scheduleStartDt = data.scheduleStartDt
      this.scheduleEndDt = data.scheduleEndDt
      this.eventList = []
      this.getJoblist()
    })
    this.$root.$on('render-map', (data) => {
      // console.log('render map ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++', data);
      this.titleDate = data.titleDate
      this.startTime = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      this.defaultView = data.name
      this.eventList = []
      if (this.$route.query.start !== undefined && this.$route.query.end !== undefined) {
        this.scheduleStartDt = moment(this.$route.query.start).format('YYYY-MM-DD')
        this.scheduleEndDt = moment(this.$route.query.end).format('YYYY-MM-DD')
        this.getJoblist()
      } else if (data.name === 'agendaDay' && data.subView === 'user') {
        this.scheduleStartDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.scheduleEndDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.getJoblist()
      } else if ( data.name === 'timelineDay' && data.subView === 'time') {
        // this.teamListData = []
        this.scheduleStartDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.scheduleEndDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.getJoblist()
      } else {
        this.scheduleStartDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.scheduleEndDt = moment(data.rangeEndDateTime).format('YYYY-MM-DD')
        this.getJoblist()
      }
      
      this.schedulerSectionHeight= data.schedulerSectionHeight + 140
      this.manageRouterQuery("1")
    })
    
    this.$root.$on('timeWiseHandler', (data) => {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      this.isLoad = false
      // this.defaultView = data.name
      // this.defaultView = 'agendaDay'
      
      this.eventList = []
      this.teamListData = []
      this.scheduleStartDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
      this.scheduleEndDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
      this.startTime = this.scheduleStartDt
        setTimeout(() => {
          this.getFilterWiseData()
        }, 1000);
        if (data.name === 'timelineDay' ) {
          this.defaultSubView = 'user'
          let arr = []
          this.employeeList.map(emp=> {
            if (emp.selected && emp.userDetailId) {
              arr.push(emp)
            }
          })
          this.teamListData = arr
        }
        if (data.name === 'agendaDay' ) {
          this.defaultSubView = 'time'
          let arr = []
          let obj = {
            userDetailId: 1,
            firstName: 'All'
          }
          arr.push(obj)
          this.teamListData = arr
        }
      this.schedulerSectionHeight= data.schedulerSectionHeight + 140
      this.manageRouterQuery("2")
    })
    
    this.$root.$on('userFilterHandler', data => {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      this.eventList = []
      this.isLoad = false
        this.userFilterlist = data.userFilterlist.toString()
     if (data.name === 'timelineDay') {
        this.scheduleStartDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.scheduleEndDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.getJoblist()
      } else {
        this.scheduleStartDt = moment(data.rangeStartDateTime).format('YYYY-MM-DD')
        this.scheduleEndDt = moment(data.rangeEndDateTime).format('YYYY-MM-DD')
        this.getJoblist()
      }
      this.startTime = this.scheduleStartDt
      this.manageRouterQuery("3")
    })
    this.$root.$on('refetchData', data => {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      this.scheduleStartDt = data.scheduleStartDt
      this.scheduleEndDt = data.scheduleEndDt
      this.eventList = []
      this.getJoblist()
      this.startTime = this.scheduleStartDt
      this.manageRouterQuery("4")
    })
    this.$root.$on('refershJobList', () => {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      this.isLoad = false
      setTimeout(() => {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        this.getFilterWiseData()
      }, 500);
    })
    this.getUnSchJobs()
    document.body.style = 'overflow: hidden;'
  },

  methods: {
    loadRouterWise () {
      this.eventList = []
      this.getTeamList()
      if (this.$route.query.view !== undefined) {
        if (this.$route.query.view !== '') {
          this.defaultView = this.$route.query.view
        }
      }
      if (this.$route.query.stime !== undefined) {
        if (this.$route.query.stime !== '') {
          this.scheduleStartDt = this.$route.query.stime
          this.startTime = this.$route.query.stime
        }
      }
      if (this.$route.query.etime !== undefined) {
        if (this.$route.query.etime !== '') {
          this.scheduleEndDt = this.$route.query.etime
        }
      }
      if (this.$route.query.user !== undefined) {
        if (this.$route.query.user !== '') {
          this.applyedUserFilters = this.$route.query.user
            this.userFilterlist = this.$route.query.user.split(',')
        }
      }
      this.eventList = []
      if (this.defaultView === 'month' || this.defaultView === 'timelineFourDay') {
        this.eventList = []
        this.getJoblist()
      }
      this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
      this.getUnSchJobs()
    },
    titleHandler (title) {
      this.shwoTitle = title
      const currentDate = new Date(); // Current date
      const startTimestamp = new Date(this.scheduleStartDt).getTime(); // Convert date1 to timestamp
      const endTimestamp = new Date(this.scheduleEndDt).getTime(); // Convert date2 to timestamp
      // Check if current date is between date1 and date2
      const isBetween = startTimestamp <= currentDate && currentDate <= endTimestamp;
      if (isBetween) {
        this.showGoto = false;
        } else {
          this.showGoto = true;
      }
    },
    selectedDateByPicker () {
      this.$root.$emit('eventHandelFromParent', 'selectedDateByPicker')
    },
    goDateCall (view) {
      this.$root.$emit('eventHandelFromParent', view)
    },
    manageRouterQuery () {
      this.$router.push({query: {user: this.userFilterlist.toString(), stime: this.scheduleStartDt, etime: this.scheduleEndDt}})
    },
    createTask () {
      this.$router.push({name: 'AddNewTask', params: {taskId: 0}})
    },
    getUnSchJobs () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
      MyJobApp.GetUnScheduleJobsOnly(
        (response) => {
          this.unscheduleventList = response.Data.JobList !== null ? response.Data.JobList  : []
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getDayOneJobVisist () {
      let startDt = new Date(this.scheduleStartDt).toUTCString()
      let endDt = new Date(new Date(this.scheduleEndDt).setHours(23, 59, 0, 0)).toUTCString()
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.UserScheduleJobVisit(
        startDt,
        endDt,
        this.userFilterlist.toString(),
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getJoblist () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      let startDt = new Date(this.scheduleStartDt).toUTCString()
      let endDt = new Date(new Date(this.scheduleEndDt).setHours(23, 59, 0, 0)).toUTCString()
      MyJobApp.ScheduleJobVisit(
        startDt,
        endDt,
        this.userFilterlist.toString(),
        (response) => {
          this.eventList = []
          this.isLoad = true
          let temp = []
          temp = response.Data.JobList !== null ? response.Data.JobList  : []
          this.eventList = temp
          this.empArray = response.Data.AssignToLastLocList !== null ? response.Data.AssignToLastLocList  : []
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getUserOndayTaskList() {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
    MyJobApp.GetUserTaskList(
      this.scheduleStartDt,
      this.scheduleEndDt,
      this.userFilterlist.toString(),
      (response) => {
        let temp = response.Data !== null ? response.Data : []
        for (let index = 0; index < temp.length; index++) {
          const sTime = moment(new Date(temp[index].startTime)).format('HH:mm:ss');
          const eTime = moment(new Date(temp[index].endTime)).format('HH:mm:ss');
          temp[index].updatedStartDate = jsCoreDateCreator(`${moment(new Date(temp[index].taskDate)).format('YYYY-MM-DD')} ${sTime}`,)
          temp[index].updatedEndDate =jsCoreDateCreator(`${moment(new Date(temp[index].taskDate)).format('YYYY-MM-DD')} ${eTime}`,)
        }
        this.isLoad = true
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      },
      (err) => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      }
    );
  },
    getTaskList() {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
    MyJobApp.GetTaskList(
      this.scheduleStartDt,
      this.scheduleEndDt,
      this.userFilterlist.toString(),
      (response) => {
        let temp = response.Data !== null ? response.Data : []
        for (let index = 0; index < temp.length; index++) {
          const sTime = moment(new Date(temp[index].startTime)).format('HH:mm:ss');
          const eTime = moment(new Date(temp[index].endTime)).format('HH:mm:ss');
          temp[index].updatedStartDate = jsCoreDateCreator(`${moment(new Date(temp[index].taskDate)).format('YYYY-MM-DD')} ${sTime}`,)
          temp[index].updatedEndDate =jsCoreDateCreator(`${moment(new Date(temp[index].taskDate)).format('YYYY-MM-DD')} ${eTime}`,)
        }
        this.isLoad = true
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      },
      (err) => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      }
    );
  },
  getTeamList () {
      MyJobApp.getEmployeeList(
        'active',
        (response) => {
          let empLi = response.Data.tableRow !== null ? response.Data.tableRow : [] 

          for (let index = 0; index < empLi.length; index++) {
            empLi[index].selected = false
            empLi[index].fullName = empLi[index].firstName + ' ' + empLi[index].lastName.substr(0, 1)
          }

          if (this.applyedUserFilters !== "") {
            let arr = this.applyedUserFilters.split(',')
            empLi.forEach(a => {
              a.selected =  false
              let fIndex = arr.findIndex(x => parseInt(x) === a.userDetailId)
              if (fIndex >= 0) {
                a.selected = true
              }
            })
              var abc = null
              if (empLi.length === arr.length) {
                const newFirstElement = {
                  userDetailId: 0,
                  fullName: "All",
                  selected: true
                }
                abc = [newFirstElement].concat(empLi)
              } else {
                const newFirstElement = {
                  userDetailId: 0,
                  fullName: "All",
                  selected: false
                }
                abc = [newFirstElement].concat(empLi)
              }

              this.employeeList = abc


              let list = []
            this.employeeList.forEach(emp=> {
              if (emp.selected && emp.userDetailId) {
                list.push(emp)
              }
            })


            if (this.defaultSubView === 'time') {
              let obj = {
                userDetailId: 1,
                firstName: 'All'
              }
              list.push(obj)
              this.teamListData = list
            } else {
              this.teamListData = list
            }
            
          }
           else {
            const newFirstElement = {
              userDetailId: 0,
              fullName: "All",
              selected: false
            }
            this.employeeList = [newFirstElement].concat(empLi)
            let arr = []
            this.employeeList.forEach(emp=> {
              if (emp.selected && emp.userDetailId) {
                arr.push(emp)
              }
            })
            if (this.defaultSubView === 'time') {
              let arr = []
              let obj = {
                userDetailId: 1,
                firstName: 'All'
              }
              arr.push(obj)
              this.teamListData = arr
            } else {
              this.teamListData = arr
            }
          }
          this.isReady = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          // console.log('call responser ------------------------');
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  toggleActiveAndInactiveB (index) {
     if (this.defaultSubView !== 'time') {
        // this.teamListData = null
     }
    
      this.userFilterlist = []
      let filter = []
      let reload = false
      if (this.employeeList[index].fullName.toLowerCase() === 'all') {
        if (!this.employeeList[0].selected) {
          this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
          this.isLoad = false
          reload = true
          this.employeeList[0].selected = true
          for (let index = 0; index < this.employeeList.length; index++) {
            this.employeeList[index].selected = true
          }
        } else {
          this.employeeList[0].selected = false
          for (let index = 0; index < this.employeeList.length; index++) {
            this.employeeList[index].selected = false
          }
        }
      } else {
        this.isLoad = false
        reload = true
        this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
        this.employeeList[index].selected = !this.employeeList[index].selected
      }

      this.employeeList.forEach(e => {
        if (e.fullName.toLowerCase() !== 'all' && e.selected) {
           filter.push(e)
        }
      });

      if (filter.length === this.employeeList.length - 1 ) {
        this.employeeList[0].selected = true
      } else {
        this.employeeList[0].selected = false
      }

        this.employeeList.map(emp=> {
          if (emp.selected && emp.userDetailId) {
            this.userFilterlist.push(emp.userDetailId)
          }
        })
      
      setTimeout(() => {
        if (reload) {
          this.getFilterWiseData()
        }
      }, 1000);
      this.manageRouterQuery("7")
    },
    getFilterWiseData () {
       this.eventList = []
      this.isLoad = true
    }
  },
  beforeDestroy () {
    this.$root.$off("render-map");
    this.$root.$off("refershJobList");
    this.$root.$off("changesinGmap");
    this.$root.$off("userFilterHandler");
    this.$root.$off("timeWiseHandler");
    document.body.style = 'overflow: visible;'
    window.removeEventListener('popstate', this.loadRouterWise);
  }
};
</script>

<style>
.schedule_map_box {
  height: 500px;
}

.fc-resource-area {
  width: 165px !important;
}

</style>
